import { requests } from "./Services";

export const HTTP_SERVICE = {
  GET_SKILLS() {
    return requests.get(`/skill`);
  },
  GET_NATIONALITIES() {
    return requests.get(`/address/nationalities`);
  },
  GET_COUNTRIES() {
    return requests.get(`/address/countries`);
  },
  GET_STATES({ countryCode }) {
    return requests.get(`/address/states/${countryCode}`);
  },
  GET_CITIES({ countryCode, stateCode }) {
    return requests.get(`/address/cities/${countryCode}/${stateCode}`);
  },
  SUBMIT(data) {
    return requests.post(`/user`, data);
  },
};

export const ADMIN_SERVICE = {
  LOGIN(data) {
    return requests.post(`/admin/login`, data);
  },
  GET_ALL(query) {
    return requests.get(`/admin/users?${query}`);
  },
  GET_STATIC_DATA() {
    return requests.get(`/admin/static`);
  },
};
