import { Button, Card, Grid, Typography } from "@mui/material";
import {
  CustomField,
  INPUT_TYPES,
  REGEX,
  getErrorKey,
  getErrorText,
} from "components";
import Header from "components/HeaderV2";
import React, { useEffect, useState } from "react";
import { DETAIL_FIELDS, DETAIL_KEYS } from "./constants";
import {
  getCities,
  getCountries,
  getNationalities,
  getSkills,
  getStates,
  submitForm,
} from "./utils";
import { validateFields } from "utils";
import { SuccessModal } from "components/SuccessModal";

export default function EnterData() {
  const [data, setData] = useState({});
  const [modalData, setModalData] = useState(null);

  const closeModal = () => setModalData(null);

  const { country, state } = data;
  const [nationalities, setNationalities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [skills, setSkills] = useState([]);

  const onChange = ({ _key, value, event }) => {
    const { target } = event || {};

    if (_key === DETAIL_KEYS.MOBILE._key) {
      if (value?.length > 15) {
        return;
      }

      if (!REGEX.NUMBERS.test(value) && !!value.length) {
        return;
      }
    }

    if (_key === DETAIL_KEYS.NO_OF_EXPERIENCES._key) {
      if (value?.length > 2) {
        return;
      }

      if (!REGEX.NUMBERS.test(value) && !!value.length) {
        return;
      }
    }

    if (target?.files?.length) {
      return setData((_state) => ({
        ..._state,
        [_key]: value,
        [`${_key}-file`]: target.files[0],
      }));
    }
    setData((_state) => ({ ..._state, [_key]: value }));
  };

  const validateData = () => {
    const { _state, isValid } = validateFields({
      _state: {
        ...data,
      },
      fields: DETAIL_FIELDS,
    });
    setData({ ..._state });

    return isValid;
  };

  const onSubmit = () => {
    if (!validateData()) {
      return;
    }

    const formData = new FormData();

    DETAIL_FIELDS.forEach(({ _key, inputType }) => {
      if (!data[_key]?.length) {
        return;
      }

      if (_key === DETAIL_KEYS.COUNTRY._key) {
        return formData.append(
          _key,
          countries.find(({ value }) => value === data[_key]).label
        );
      }

      if (_key === DETAIL_KEYS.STATE._key) {
        return formData.append(
          _key,
          states.find(({ value }) => value === data[_key]).label
        );
      }

      if (inputType === INPUT_TYPES.FILE) {
        return formData.append("file", data[`${_key}-file`]);
      }
      if (Array.isArray(data[_key])) {
        data[_key].forEach(({ value }) => {
          formData.append(_key, value);
        });
      } else {
        formData.append(_key, data[_key]);
      }
    });

    submitForm({
      payload: formData,
      onSuccess: () => {
        setData({});
        setModalData({
          title: "Success",
          description:
            "Form submitted successfully. Thank you for providing your information.",
          footerButtons: [
            {
              onClick: () => setModalData(null),
              sx: { px: "20px", mx: "8px" },
              title: "OK",
            },
          ],
        });
      },
    });
  };

  useEffect(() => {
    getNationalities({
      onSuccess: (data) => {
        setNationalities(data);
      },
    });

    getCountries({
      onSuccess: (data) => {
        setCountries(data);
      },
    });

    getSkills({
      onSuccess: (data) => setSkills(data),
    });
  }, []);

  useEffect(() => {
    if (!country) return;

    getStates({
      payload: { countryCode: country },
      onSuccess: (data) => {
        setStates(data);
        setData((_state) => ({ ..._state, state: "", city: "" }));
      },
    });
  }, [country]);

  useEffect(() => {
    if (!country || !state) return;

    getCities({
      payload: {
        countryCode: country,
        stateCode: state,
      },
      onSuccess: (data) => setCities(data),
    });
  }, [country, state]);

  return (
    <Typography
      component={"div"}
      sx={{ background: "#1087dd1f", py: { xs: 0, sm: 4 } }}
    >
      <Card
        sx={{
          p: 3,
          rowGap: 2,
          display: "flex",
          flexDirection: "column",
          width: { xs: "100%", sm: "50vw" },
          mx: "auto",
        }}
      >
        <Header
          title="DSS Volunteers Form"
          variant="h4"
          titleStyle={{ fontWeight: 600 }}
        />
        <Grid container rowSpacing={2} spacing={3}>
          {DETAIL_FIELDS.map((item, index) => {
            const { _key, md } = item;

            if (!_key) return null;

            if (_key === DETAIL_KEYS.TECH_OR_SOFT_SKILLS._key) {
              item.extraData = skills;
            }

            if (_key === DETAIL_KEYS.NATIONALITY._key) {
              item.extraData = nationalities;
            }

            if (_key === DETAIL_KEYS.COUNTRY._key) {
              item.extraData = countries;
            }

            if (_key === DETAIL_KEYS.STATE._key) {
              item.extraData = states;
            }

            if (_key === DETAIL_KEYS.CITY._key) {
              item.extraData = cities;
            }

            return (
              <Grid key={_key + index} item md={md || 6} sm={12} xs={12}>
                <CustomField
                  color={"primary"}
                  item={item}
                  value={data[_key]}
                  onChange={onChange}
                  error={data[getErrorKey(_key)]}
                  errorText={data[getErrorText(_key)]}
                />
              </Grid>
            );
          })}
        </Grid>

        <Typography component={"div"} sx={{ display: "flex", gap: 4 }}>
          <Button
            onClick={() => {
              setData({});
            }}
            color={"primary"}
            variant="outlined"
            sx={{ px: "20px", mt: "20px", width: "100%" }}
          >
            Reset
          </Button>

          <Button
            onClick={() => {
              onSubmit();
            }}
            color={"primary"}
            variant="contained"
            sx={{ px: "20px", mt: "20px", width: "100%" }}
          >
            Submit
          </Button>
        </Typography>
      </Card>

      {!!modalData && (
        <SuccessModal closeModal={closeModal} modalData={modalData} />
      )}
    </Typography>
  );
}
