import React from "react";
import { Box } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import ViewData from "screens/ViewData";
import CustomAppBar from "components/CustomDrawer/CustomAppBar";

export const MainWrapper = ({ theme }) => {
  return (
    <Box sx={{ display: "flex", overflow: "hidden" }}>
      <CustomAppBar />
      <Box
        component="main"
        sx={{
          width: "100%",
          flexGrow: 1,
          p: 1.5,
          marginTop: "48px",
          minHeight: "100vh",
          height: "100%",
          overflow: "auto",
          boxShadow: "inset rgb(0 0 0 / 7%) 0px 0px 21px 1px",
          background: "#1087dd1f !important",
        }}
      >
        <Routes>
          <Route path="/" element={<ViewData />}></Route>

          <Route
            path="/*"
            element={
              <main
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  height: "80vh",
                }}
              >
                <p
                  style={{
                    fontSize: "100px",
                    lineHeight: "100px",
                    fontWeight: 600,
                    margin: 0,
                  }}
                >
                  404
                </p>
                <p style={{ fontSize: "18px", margin: 0, fontWeight: 500 }}>
                  PAGE NOT FOUND
                </p>
              </main>
            }
          />
        </Routes>
      </Box>
    </Box>
  );
};
